import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { paymentClient, tournamentsClient } from 'src/apollo/client';
import { useOrgId, useOrgName } from 'src/apollo/local-state';
import Alert from 'src/components/alert/alert';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import RadioButtonGroup from 'src/components/radio-button-group/radio-button-group';
import Spinner from 'src/components/spinner/spinner';
import StatusLabel from 'src/components/status-label/status-label';
import { BodyLarge, BodyRegular } from 'src/components/typography/typography';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { GenerateNewAccountUrl, GenerateNewAccountUrlVariables } from 'src/graphql-types/GenerateNewAccountUrl';
import {
  GetDefaultAccount_venueDefaultAccount as Account,
  GetDefaultAccount_venueDefaultAccount_status as AccountStatusType,
  GetDefaultAccount,
  GetDefaultAccountVariables,
} from 'src/graphql-types/GetDefaultAccount';
import {
  GetDefaultAccountLoginLink,
  GetDefaultAccountLoginLinkVariables,
} from 'src/graphql-types/GetDefaultAccountLoginLink';
import { GetOrgUrlSegment, GetOrgUrlSegmentVariables } from 'src/graphql-types/GetOrgUrlSegment';
import { retrieveUser } from 'src/utils/storage/local-storage';

import {
  GENERATE_NEW_ACCOUNT_URL,
  GET_ACCOUNT_LOGIN_LINK,
  GET_DEFAULT_ACCOUNT,
  GET_ORG_URL_SEGMENT,
} from './payment-settings-queries';
import * as styles from './payment-settings.module.less';

const PaymentSettings: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useOrgId();
  const facility = useOrgName();

  const { data, loading } = useQuery<GetDefaultAccount, GetDefaultAccountVariables>(GET_DEFAULT_ACCOUNT, {
    client: paymentClient,
    variables: { orgId },
  });

  const account = data?.venueDefaultAccount;
  const accountIsMine = useMemo(() => retrieveUser()?.ID === data?.venueDefaultAccount?.userId, [data]);

  const [changingAccount, setChangingAccount] = useState(false);

  const [getLoginLink, { data: loginLinkData, loading: loadingLoginLink }] = useLazyQuery<
    GetDefaultAccountLoginLink,
    GetDefaultAccountLoginLinkVariables
  >(GET_ACCOUNT_LOGIN_LINK, { client: paymentClient });

  const onActionButtonClick = useCallback(() => {
    if (accountIsMine) {
      getLoginLink({ variables: { orgId } });
    } else {
      setChangingAccount(!changingAccount);
    }
  }, [accountIsMine, getLoginLink, orgId, setChangingAccount, changingAccount]);

  useEffect(() => {
    if (loginLinkData?.venueDefaultAccount?.loginLink && typeof window !== 'undefined') {
      window.location.href = loginLinkData.venueDefaultAccount?.loginLink;
    }
  }, [loginLinkData]);

  const userAccountRestricted = useMemo(() => {
    const { chargesEnabled, payoutsEnabled } = account?.status ?? {};
    const approved = chargesEnabled && payoutsEnabled;
    return accountIsMine && !approved;
  }, [accountIsMine, account]);

  const actionButtonLoading = loadingLoginLink;

  return (
    <Panel
      title={t('connected account')}
      headerEndContent={
        account && (
          <Button
            level={changingAccount ? 'secondary' : 'primary'}
            loading={actionButtonLoading}
            onClick={onActionButtonClick}
            size={'sm'}
            spacing={{ margins: { sm: 'left' } }}
          >
            {t(accountIsMine ? 'edit account' : changingAccount ? 'cancel' : 'change')}
          </Button>
        )
      }
    >
      {userAccountRestricted && (
        <Alert variant={'error'} spacing={{ margins: { md: 'vertical' } }}>
          {t('connected account restricted')}
        </Alert>
      )}
      <div className={styles.connectedAccount}>
        <Icon name={'xl-payment'} />
        <div className={styles.connectedAccountContent}>
          {loading && <Spinner />}
          {!loading &&
            (account && !changingAccount ? (
              <ConnectedAccountInfo account={account} accountIsMine={accountIsMine} />
            ) : (
              <SetUpAccount firstTime={!changingAccount} />
            ))}
        </div>
      </div>
      <BodyRegular light spacing={{ margins: { lg: 'top' } }}>
        {t('connected account info', { facility })}
      </BodyRegular>
    </Panel>
  );
};

interface ConnectedAccountProps {
  account: Account;
  accountIsMine: boolean;
}

const ConnectedAccountInfo: React.FC<ConnectedAccountProps> = ({
  account: { firstName, lastName, email, created, status },
  accountIsMine,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AccountInfoItem
        label={t('account owner')}
        value={
          <>
            {firstName} {lastName} {accountIsMine && <span className={styles.you}> ({t('you')})</span>}
          </>
        }
      />
      <AccountInfoItem label={t('email')} value={email} />
      <AccountInfoItem label={t('date created')} value={t('date created payment', { date: dayjs.unix(created) })} />
      <AccountInfoItem label={t('status')} value={<AccountStatus status={status} />} />
    </>
  );
};

interface AccountInfoItemProps {
  label: ReactNode;
  value: ReactNode | null;
}

const AccountInfoItem: React.FC<AccountInfoItemProps> = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.accountInfo}>
      <span className={styles.accountInfoLabel}>{label}:</span> {value || t('n/a')}
    </div>
  );
};

interface AccountStatusProps {
  status: AccountStatusType;
}

const AccountStatus: React.FC<AccountStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const approved = status.chargesEnabled && status.payoutsEnabled;
  return (
    <StatusLabel variety={approved ? 'success' : 'error'}>{approved ? t('approved') : t('restricted')}</StatusLabel>
  );
};

interface SetUpAccountProps {
  firstTime: boolean;
}

interface NewAccountUrlPayload {
  type: string;
  userId: string;
  venueId: string;
  country?: string;
  redirectUrl: string;
  additionalStateSegments: string[];
  description?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  setVenueDefault?: boolean;
}

const SetUpAccount: React.FC<SetUpAccountProps> = ({ firstTime }) => {
  const [accountTypeStep, setAccounntTypeStep] = useState(!firstTime);
  const [selectedAccountType, setSelectedAccountType] = useState('individual');

  const { t } = useTranslation();
  const orgId = useOrgId();
  const facility = useOrgName();

  const {
    data: orgData,
    loading: loadingOrgData,
    error: orgDataError,
  } = useQuery<GetOrgUrlSegment, GetOrgUrlSegmentVariables>(GET_ORG_URL_SEGMENT, {
    client: tournamentsClient,
    variables: { orgId },
    skip: !accountTypeStep,
  });

  const [generateNewAccountUrl, { loading, error }] = useMutation<
    GenerateNewAccountUrl,
    GenerateNewAccountUrlVariables
  >(GENERATE_NEW_ACCOUNT_URL, { client: paymentClient });

  const userInfo = useMemo(retrieveUser, []);

  const setUpAccount = () => {
    if (!orgData?.organisation) {
      return;
    }
    const { ID: id, FirstName: firstName, LastName: lastName, EmailAddress: email } = userInfo;

    const payload: NewAccountUrlPayload = {
      type: selectedAccountType,
      userId: id,
      venueId: orgId,
      country: getClientConfig().stripeCountry,
      redirectUrl: getEnvConfig().PAYMENT_CLASSIC_REDIRECT_URL,
      additionalStateSegments: [
        `${getEnvConfig().PAYMENT_CC_REDIRECT_URL}`, // final redirect url back here
      ],
    };

    if (selectedAccountType === 'individual') {
      Object.assign(payload, {
        description: `${facility} default account`,
        email,
        firstName,
        lastName,
        setVenueDefault: true,
      });
    }

    generateNewAccountUrl({
      variables: {
        accountIntegration: payload,
      },
      onCompleted: ({ createAccountIntegration }) => {
        window.location.href = createAccountIntegration;
      },
    });
  };

  return (
    <>
      {firstTime && (
        <BodyLarge spacing={{ margins: { sm: 'vertical' } }}>{t('no connected account', { facility })}</BodyLarge>
      )}
      <div className={styles.setupButtonContainer}>
        {!accountTypeStep ? (
          <>
            <Button
              spacing={{ margins: { sm: 'right' } }}
              loading={loading || loadingOrgData}
              onClick={() => setAccounntTypeStep(true)}
            >
              {t('set up account')}
            </Button>
            <APIErrorMessage error={orgDataError?.message ?? error?.message} />
          </>
        ) : (
          <>
            <BodyRegular>{t('select account type')}</BodyRegular>
            <RadioButtonGroup
              value={selectedAccountType}
              handleChange={(e) => setSelectedAccountType(e.target.value)}
              options={[
                { value: 'individual', label: t('individual') },
                { value: 'company', label: t('company') },
              ]}
            />
            <Button
              spacing={{ margins: { sm: ['right', 'top'] } }}
              loading={loading || loadingOrgData}
              onClick={setUpAccount}
              disabled={!orgData}
            >
              {t('stripe redirect')}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default PaymentSettings;
