import graphql from 'graphql-tag';

export const GET_ORG_CONFIG = graphql`
  query OrgConfig($orgId: ID!) {
    orgConfig(orgId: $orgId) {
      organisationId
      hierarchy {
        organisationId
        configType
        tieFormatTemplates
      }
      raw {
        tieFormatTemplates
        organisationId
        configType
      }
    }
  }
`;
export const SET_ORG_CONFIG = graphql`
  mutation SetOrgConfig($orgId: ID!, $config: desk_OrgConfigInput!) {
    setOrgConfig(orgId: $orgId, config: $config) {
      organisationId
      hierarchy {
        organisationId
        configType
        tieFormatTemplates
      }
      raw {
        tieFormatTemplates
        organisationId
        configType
      }
    }
  }
`;
