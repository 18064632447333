// extracted by mini-css-extract-plugin
export var bodyBase = "tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodyLarge = "tournaments-settings-module--body-large--63223 tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodyLargeBold = "tournaments-settings-module--body-large-bold--4416b tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodyRegular = "tournaments-settings-module--body-regular--83ba1 tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodyRegularBold = "tournaments-settings-module--body-regular-bold--8b1ae tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodySmall = "tournaments-settings-module--body-small--8de2d tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var bodySmallBold = "tournaments-settings-module--body-small-bold--164d5 tournaments-settings-module--body-base--fb4c9 tournaments-settings-module--site-font--09c28";
export var borderTop = "tournaments-settings-module--border-top--b503f";
export var breakWordContainer = "tournaments-settings-module--break-word-container--b4553";
export var buttonIconBase = "tournaments-settings-module--button-icon-base--421f7";
export var clickLink = "tournaments-settings-module--click-link--7f529";
export var dropdownBase = "tournaments-settings-module--dropdown-base--e90ba";
export var dropdownSelectBase = "tournaments-settings-module--dropdown-select-base--318bd tournaments-settings-module--text-input--aeb47";
export var flexCol = "tournaments-settings-module--flex-col--087d6";
export var form = "tournaments-settings-module--form--251d9";
export var formErrorMessage = "tournaments-settings-module--form-error-message--0b259";
export var h3 = "tournaments-settings-module--h3--d8379";
export var h4 = "tournaments-settings-module--h4--371c9";
export var hoverLink = "tournaments-settings-module--hover-link--d93c0";
export var hoverRow = "tournaments-settings-module--hover-row--35793";
export var membershipContainer = "tournaments-settings-module--membership-container--c9e51 tournaments-settings-module--flex-col--087d6 tournaments-settings-module--primary-border--f6249";
export var membershipHeader = "tournaments-settings-module--membership-header--2428c";
export var membershipHeading = "tournaments-settings-module--membership-heading--df919";
export var membershipLabel = "tournaments-settings-module--membership-label--9c83a";
export var moreFiltersBorderClass = "tournaments-settings-module--more-filters-border-class--b3d98";
export var pageBg = "tournaments-settings-module--page-bg--ec9bb";
export var partPage = "tournaments-settings-module--part-page--49c06";
export var pointer = "tournaments-settings-module--pointer--9eafe";
export var primaryBorder = "tournaments-settings-module--primary-border--f6249";
export var shadowBoxLight = "tournaments-settings-module--shadow-box-light--e409e";
export var siteFont = "tournaments-settings-module--site-font--09c28";
export var slideDownAndFade = "tournaments-settings-module--slideDownAndFade--06b4a";
export var slideLeftAndFade = "tournaments-settings-module--slideLeftAndFade--59e5f";
export var slideRightAndFade = "tournaments-settings-module--slideRightAndFade--716d2";
export var slideUpAndFade = "tournaments-settings-module--slideUpAndFade--fcf85";
export var statusDecoration = "tournaments-settings-module--status-decoration--13b0f";
export var textInput = "tournaments-settings-module--text-input--aeb47";
export var textInverted = "tournaments-settings-module--text-inverted--dcb9d";
export var textMediumDark = "tournaments-settings-module--text-medium-dark--58ff2";
export var tick = "tournaments-settings-module--tick--9254e";
export var tooltipFont = "tournaments-settings-module--tooltipFont--f5ce2";
export var unstyledButton = "tournaments-settings-module--unstyled-button--5bde6";