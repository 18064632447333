import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminCSVUpload from 'src/components/admin-csv-upload/admin-csv-upload';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import Button from 'src/components/button/button';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import { SwitchField } from 'src/components/formik-fields/formik-fields';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import PaymentSettings from 'src/components/payment-settings/payment-settings';
import ScorecardTemplates from 'src/components/scorecard-templates/scorecard-templates';
import Spinner from 'src/components/spinner/spinner';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getClientConfig } from 'src/config/config';
import {
  GetOrgSettings,
  GetOrgSettingsVariables,
  GetOrgSettings_organisation_settings as OrgSettings,
} from 'src/graphql-types/GetOrgSettings';
import { UpdateOrgSettings, UpdateOrgSettingsVariables } from 'src/graphql-types/UpdateOrgSettings';
import { StaffScope, useHasScope } from 'src/utils/auth';
import * as Yup from 'yup';

import { GET_ORG_SETTINGS, UPDATE_ORG_SETTINGS } from './tournaments-settings-queries';
import * as styles from './tournaments-settings.module.less';

const TournamentSettings: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useOrgId();
  const { disableTournamentSettings } = getClientConfig();
  const paymentsScope = useHasScope([StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN]);

  const { data, loading, error } = useQuery<GetOrgSettings, GetOrgSettingsVariables>(GET_ORG_SETTINGS, {
    variables: { orgId },
    client: tournamentsClient,
  });

  return (
    <PageMaxWidth>
      <div className={styles.partPage}>
        <PageHeader title={t('tournament settings')} />
        {paymentsScope && <PaymentSettings />}
        {!disableTournamentSettings && (
          <TournamentSettingsPanel
            settings={data?.organisation?.settings}
            apiError={error?.message}
            loading={loading}
          />
        )}
        {data?.organisation?.hierarchy.length === 1 && <AdminCSVUpload />}
        <ScorecardTemplates />
      </div>
    </PageMaxWidth>
  );
};

interface TournamentSettingsPanelProps {
  settings?: OrgSettings | null;
  apiError?: string;
  loading?: boolean;
}

const TournamentSettingsPanel: React.FC<TournamentSettingsPanelProps> = ({ settings, apiError, loading }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const [updateSettings, { loading: updating, error: updateError, data: updateData }] = useMutation<
    UpdateOrgSettings,
    UpdateOrgSettingsVariables
  >(UPDATE_ORG_SETTINGS, { client: tournamentsClient });

  const validationSchema = useMemo(() => Yup.object({ sectionalTdOverride: Yup.boolean().required() }), []);
  type FormData = Yup.InferType<typeof validationSchema>;

  const initialValues = useMemo<FormData | undefined>(() => {
    if (settings) {
      const { tournamentDirectorsCanBeUsedByChildOrganisations } = settings;
      return {
        sectionalTdOverride: tournamentDirectorsCanBeUsedByChildOrganisations,
      };
    }
    if (!loading) return { sectionalTdOverride: false };
  }, [settings, loading]);

  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = useCallback(
    (values: FormData) => {
      setShowSuccess(false);
      updateSettings({
        variables: {
          orgId,
          settings: {
            tournamentDirectorsCanBeUsedByChildOrganisations: values.sectionalTdOverride,
          },
        },
      });
    },
    [updateSettings, orgId, setShowSuccess],
  );

  useEffect(() => {
    if (updateData) setShowSuccess(true);
  }, [updateData]);

  return (
    <Panel title={t('settings')}>
      {loading && <Spinner />}
      {initialValues && (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
          <Form className={styles.form}>
            <SwitchField horizontal={false} name="sectionalTdOverride" label={`${t('sectional td override')}:`} />
            <Button loading={updating} spacing={{ margins: { lg: 'top' } }} type="submit">
              {t('save')}
            </Button>
          </Form>
        </Formik>
      )}
      <APIErrorMessage error={apiError || updateError?.message} />
      {showSuccess && (
        <FloatingNotification
          icon={{ name: 'md-tick', className: styles.tick }}
          message={t('settings updated')}
          onClose={() => setShowSuccess(false)}
        />
      )}
    </Panel>
  );
};

export default TournamentSettings;
