import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import TournamentSettings from 'src/components/tournaments-settings/tournaments-settings';

const TournamentSettingsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Tournament Settings" />
      <PrivateRoute>
        <TournamentSettings />
      </PrivateRoute>
    </Layout>
  );
};

export default TournamentSettingsPage;
