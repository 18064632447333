import gql from 'graphql-tag';

const ORG_SETTINGS_FIELDS_FRAGMENT = gql`
  fragment OrgSettingsFields on OrganisationSettings {
    id
    nonSanctionedTournamentFee
    tournamentDirectorsCanBeUsedByChildOrganisations
  }
`;

export const UPDATE_ORG_SETTINGS = gql`
  mutation UpdateOrgSettings($orgId: UUID!, $settings: OrganisationSettingsInput!) {
    updateOrganisationSettings(organisationId: $orgId, organisationSettings: $settings) {
      ...OrgSettingsFields
    }
  }
  ${ORG_SETTINGS_FIELDS_FRAGMENT}
`;

export const GET_ORG_SETTINGS = gql`
  query GetOrgSettings($orgId: UUID!) {
    organisation(id: $orgId) {
      hierarchy
      settings {
        ...OrgSettingsFields
      }
    }
  }
  ${ORG_SETTINGS_FIELDS_FRAGMENT}
`;
